import { styled } from "src/ccl/stitches";
import { Flex } from "src/ccl/layout/flex";

export const Container = styled(Flex, {
  px: "$6",
  mx: " auto",
  width: "100%",
  maxWidth: "1920px",

  "@sm": {
    px: "$9",
  },

  "@xl": {
    px: "$16",
  },
});
