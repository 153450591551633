// packageStore.ts
import { action, Action, computed, Computed, persist } from "easy-peasy";
import { Package, User } from "src/graphql/generated/types";

type Status = "blank" | "talentSelected" | "jobDetailsFilled" | "readyToSubmit";

interface PackageStoreState {
  shortlistedTalents: User[];
  packageJob: Package | null;
}

export interface PackageStore {
  shortlistedTalents: User[];
  setShortlistedTalents: Action<PackageStoreState, User[]>;
  addTalent: Action<PackageStoreState, User>;
  removeTalent: Action<PackageStoreState, string>;
  packageJob: Package | null;
  setPackageJob: Action<PackageStoreState, Package>;
  clearPackageData: Action<PackageStoreState>;
  status: Computed<PackageStoreState, Status>;
}

const persistInLocalStorage = (model: PackageStore) =>
  persist(model, { storage: "localStorage" });

export const packageStore: PackageStore = persistInLocalStorage({
  shortlistedTalents: [],
  setShortlistedTalents: action((state, payload) => {
    state.shortlistedTalents = payload;
  }),
  addTalent: action((state, talent) => {
    state.shortlistedTalents.push(talent);
  }),
  removeTalent: action((state, talentId) => {
    state.shortlistedTalents = state.shortlistedTalents.filter(
      (t) => t.id !== talentId,
    );
  }),
  packageJob: null,
  setPackageJob: action((state, job) => {
    state.packageJob = job;
  }),
  clearPackageData: action((state) => {
    state.shortlistedTalents = [];
    state.packageJob = null;
  }),
  status: computed((state) => {
    if (state.packageJob && state.shortlistedTalents.length > 0) {
      return "readyToSubmit";
    }
    if (state.shortlistedTalents.length > 0) {
      return "talentSelected";
    }
    return "blank";
  }),
});
