import { JobAssigneeGrid } from "src/components/jobAssignees";
import { Icon, PulsatingIcon, Text } from "src/ccl/document";
import { Avatar } from "src/ccl/blocks";
import { FloatingPopover } from "src/ccl/feedback";
import { Flex, Box } from "src/ccl/layout";
import { Link } from "src/ccl/navigation/link";
import { formatDate } from "src/utils/dates";
import { getInitials, niceStateText } from "src/utils/lang";
import { Job, Assignee, JobState, JobTalent } from "src/graphql/types";
import { tokens } from "src/ccl/stitches/theme";
import { BookerDashboardJobsTalent } from "src/ccl/jobs/jobCard/BookerDashboardJobsTalent";
import { verticalIconBackgroundMap, verticalIconMap } from "src/utils/user";
import { BookerJobStateTheme } from "src/graphql/colors";
import { useStoreModel, useUserKindContext } from "src/hooks";

const DateFormat = "d LLL yyyy";

export interface KanbanJobCardProps {
  job: Job;
  assignees: Assignee[];
}

const pulsatingIconStates = [JobState.Approved, JobState.Finalized];

export const KanbanJobCard = ({ job, assignees }: KanbanJobCardProps) => {
  const startDate: string = formatDate(job.startDate, DateFormat);

  const currentUser = useStoreModel("currentUser");
  const context = useUserKindContext(currentUser);

  const [, jobStateColorSecondary, jobStateColorTertiary] =
    BookerJobStateTheme[job.state];

  const navigateTo = () => {
    let link = `/dashboard/${context}/jobs/${job.slug}`;

    if (context === "TPA") {
      link = `/dashboard/agent/jobs/${job.slug}`;
    }

    return link;
  };

  return (
    <Flex
      css={{
        maxWidth: "100%",
        flexDirection: "column",
        border: "1.5px solid $grey2",
        background: "$white",
        borderRadius: "$4",
      }}
    >
      <Box
        css={{
          padding: "$7",
        }}
      >
        <Flex css={{ flexDirection: "column", gap: "$6" }}>
          <Flex css={{ justifyContent: "space-between" }}>
            <Flex css={{ alignItems: "center", gap: "10px" }}>
              {job.state === JobState.Completed ? (
                <Icon variant={"tick"} size={16} />
              ) : pulsatingIconStates.includes(job.state) ? (
                <PulsatingIcon
                  color={tokens.colors["green"] as keyof typeof tokens.colors}
                  backgroundColor={"$green" as keyof typeof tokens.colors}
                  variant="small"
                />
              ) : (
                <></>
              )}
              <Link
                to={navigateTo()}
                css={{
                  textDecoration: "none",
                  wordBreak: "break-all",
                  pr: "$4",
                }}
              >
                <Text variant="b1Bold" css={{ wordBreak: "break-word" }}>
                  {job.name}
                </Text>
              </Link>
            </Flex>
            <Flex css={{ gap: "10px" }}>
              <Flex
                css={{
                  gap: "6px",
                  flexWrap: "wrap",
                  display: "none",
                  "@lg": { display: "flex" },
                }}
              >
                {job.jobVerticals.map((jobVerticals, index) => (
                  <Flex
                    key={index}
                    css={{
                      width: "30px",
                      height: "30px",
                      background: `$${
                        verticalIconBackgroundMap[jobVerticals.vertical]
                      }`,
                      borderRadius: "$round",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      variant={verticalIconMap[jobVerticals.vertical]}
                      size={18}
                    />
                  </Flex>
                ))}
              </Flex>
              {!currentUser.isBooker && (
                <FloatingPopover
                  name="add-assinees"
                  direction="down"
                  launcher={
                    job.assignedAgent ? (
                      <Avatar
                        imageUrl={job.assignedAgent.featuredAsset?.mediaUrl}
                        initials={getInitials(job.assignedAgent.name)}
                      />
                    ) : (
                      <Flex
                        css={{
                          backgroundColor: "$grey2",
                          borderRadius: "$round",
                          width: "$avatar",
                          height: "$avatar",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                          cursor: "pointer",
                        }}
                      >
                        <Icon variant="plus" color="black" size={14} />
                      </Flex>
                    )
                  }
                >
                  <JobAssigneeGrid job={job} assignees={assignees} />
                </FloatingPopover>
              )}
            </Flex>
          </Flex>
          <Flex
            css={{
              flexDirection: "column",
              gap: "10px",
              "@lg": {
                flexDirection: "row",
                gap: "$5",
              },
            }}
          >
            <Flex css={{ alignItems: "center", gap: "10px" }}>
              <Icon variant="calendar" size={24} color="grey5" />
              <Text variant="b3" color="grey5">
                {startDate}
              </Text>
            </Flex>
            <Flex css={{ alignItems: "center", gap: "10px" }}>
              <Icon variant="brand" size={24} color="grey5" />
              <Text variant="b3" color="grey5">
                {job.brand}
              </Text>
            </Flex>
          </Flex>
          <Flex
            css={{
              flexDirection: "column",
              gap: "$5",
              "@lg": {
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              },
            }}
          >
            {job.talent && job.talent.length > 0 && (
              <Flex
                css={{ alignItems: "center", gap: "8px", flexWrap: "wrap" }}
              >
                <BookerDashboardJobsTalent
                  talent={job?.talent as JobTalent[]}
                  job={job}
                  size={38}
                  overlap={12}
                  showLeftover={true}
                  reverseOverlap={true}
                />
                <Text variant="b3" color="grey5">
                  {job.talent.length} Shortlisted
                </Text>
              </Flex>
            )}
            <Flex css={{ flexDirection: "column", gap: "$4" }}>
              <Flex
                css={{
                  gap: "6px",
                  flexWrap: "wrap",
                  "@lg": {
                    display: "none",
                  },
                }}
              >
                {job.jobVerticals.map((jobVerticals, index) => (
                  <Flex
                    key={index}
                    css={{
                      width: "30px",
                      height: "30px",
                      background: `$${
                        verticalIconBackgroundMap[jobVerticals.vertical]
                      }`,
                      borderRadius: "$round",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      variant={verticalIconMap[jobVerticals.vertical]}
                      size={18}
                    />
                  </Flex>
                ))}
              </Flex>
              <Box
                css={{
                  width: "max-content",
                  backgroundColor: jobStateColorSecondary,
                  borderRadius: "$pill",
                }}
              >
                <Text
                  variant="b3Bold"
                  css={{ color: jobStateColorTertiary, padding: "8px 12px" }}
                >
                  {niceStateText(job.state)}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Flex>

        {job.virtual && (
          <Flex
            css={{
              pt: "$4",
              alignItems: "center",
            }}
          >
            <Icon
              variant="virtual"
              size={20}
              color="grey6"
              css={{ mr: "$3" }}
            />
            <Text variant="mini" css={{ color: "$grey6" }}>
              Virtual job
            </Text>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
