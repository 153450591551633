import { Box, Flex, Grid } from "src/ccl/layout";
import {
  BrandFilter,
  DateRangeFilter,
  JobStateFilter,
  SearchFilter,
  VerticalFilter,
} from "src/components/filtering";
import { MobileFilterButton } from "src/components/filtering/MobileFilterButton";
import { OwnerFilter } from "src/components/filtering/jobFilters/OwnerFilter";
import {
  DateRangeInput,
  JobState,
  SortOrderInput,
  TalentVertical,
} from "src/graphql/types";
import { useStoreModel, useToggle } from "src/hooks";
import { JobFilterModal } from "src/components/dashboards/agents/jobs/JobFilterModal";
import { JobSortModal } from "src/components/dashboards/agents/jobs/JobSortModal";
import { Button } from "src/ccl/navigation";
import { Icon } from "src/ccl/document";

interface Owner {
  label: string;
  value: string;
}

interface JobFilterProps {
  view: "simple" | "status";
  jobCount?: number;
  onReset: () => void;
  nameOrBrand: string;
  setNameOrBrand: (value: string) => void;
  verticals: TalentVertical[];
  setVerticals: (verticals: TalentVertical[]) => void;
  availableJobStates: JobState[];
  states: JobState[];
  setStates: (states: JobState[]) => void;
  owner: Owner | undefined;
  setOwner: (value: string) => void;
  ownerOptions: Owner[];
  dateRange: DateRangeInput | undefined;
  onDateRangeChange: (dateRange?: DateRangeInput) => void;
  sortOrder: SortOrderInput;
  setSortOrder: (sortOrderInput: SortOrderInput) => void;
  brands?: string[];
  setBrands?: (brands: string[]) => void;
  brandOptions?: Array<string>;
  onViewChange: (view: "simple" | "status") => void;
}

export const JobFilter = ({
  view,
  jobCount,
  onReset,
  nameOrBrand,
  setNameOrBrand,
  verticals,
  setVerticals,
  availableJobStates,
  states,
  setStates,
  owner,
  setOwner,
  ownerOptions,
  dateRange,
  onDateRangeChange,
  sortOrder,
  setSortOrder,
  brands = [],
  setBrands = () => {},
  brandOptions = [],
  onViewChange,
}: JobFilterProps) => {
  const { isBooker } = useStoreModel("currentUser");
  const [sortModalOpen, toggleSortModal] = useToggle();
  const [filterModalOpen, toggleFilterModal] = useToggle();

  return (
    <Box>
      <Flex
        css={{
          gap: "14px",
          display: "none",
          "@lg": { display: "flex" },
        }}
      >
        <SearchFilter
          placeholder="Search jobs"
          search={nameOrBrand}
          onChange={setNameOrBrand}
        />
        <VerticalFilter verticals={verticals} onChange={setVerticals} />
        {view === "simple" && (
          <JobStateFilter
            availableStates={availableJobStates}
            states={states}
            onChange={setStates}
          />
        )}
        {isBooker ? (
          <BrandFilter
            brands={brands}
            onChange={setBrands}
            brandOptions={brandOptions}
          />
        ) : (
          <OwnerFilter
            owner={owner}
            setOwner={setOwner}
            ownerOptions={ownerOptions}
          />
        )}

        <DateRangeFilter dateRange={dateRange} onChange={onDateRangeChange} />
      </Flex>
      <Grid
        css={{
          "@lg": { display: "none" },
          gridTemplateColumns: "2fr 2fr 1fr 1fr", // Adjust column widths
          borderBottom: "1px solid $grey2",
          pb: "$4",
          width: "100%",
        }}
      >
        <MobileFilterButton
          text="Sort"
          icon="sortRows"
          borderRight={true}
          onClick={toggleSortModal}
        />
        <MobileFilterButton
          text="Filter"
          icon="filter"
          onClick={toggleFilterModal}
          borderRight={true}
        />
        <Button
          variant="unstyled"
          css={{
            background: view === "status" ? "$grey5" : "$grey1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "$2",
            "&:hover": {
              background: "$grey4",
            },
            borderRight: "1px solid $grey2",
          }}
          onClick={() => onViewChange("status")}
        >
          <Icon
            size={28}
            variant="gridView"
            color={view === "status" ? "white" : "black"}
          />
        </Button>
        <Button
          variant="unstyled"
          css={{
            background: view === "simple" ? "$grey5" : "$grey1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "$2",
            "&:hover": {
              background: "$grey4",
            },
          }}
          onClick={() => onViewChange("simple")}
        >
          <Icon
            size={28}
            variant="listView"
            color={view === "simple" ? "white" : "black"}
          />
        </Button>
      </Grid>
      <JobFilterModal
        isOpen={filterModalOpen}
        onClose={toggleFilterModal}
        view={view}
        jobCount={jobCount}
        onReset={onReset}
        nameOrBrand={nameOrBrand}
        setNameOrBrand={setNameOrBrand}
        verticals={verticals}
        setVerticals={setVerticals}
        availableJobStates={availableJobStates}
        states={states}
        setStates={setStates}
        owner={owner}
        setOwner={setOwner}
        ownerOptions={ownerOptions}
        onDateRangeChange={onDateRangeChange}
        brands={brands}
        setBrands={setBrands}
        brandOptions={brandOptions}
        isBooker={isBooker}
      />
      <JobSortModal
        isOpen={sortModalOpen}
        onClose={toggleSortModal}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />
    </Box>
  );
};
