import { createStore } from "easy-peasy";
import { currentUserModel, CurrentUserModel } from "./currentUser";
import { jobsModel, JobsModel } from "./jobs";
import { contentfulModel, ContentfulModel } from "./contentful";
import { s3Model, S3Model } from "./s3";
import { draftTalentModel, DraftTalentModel } from "./agencies/addTalentDraft";
import { preferencesModel, PreferencesModel } from "./preferences";
import {
  AgencyDashboardFiltersModel,
  agencyDashboardFiltersModel,
} from "./agencyDashboardFilters";
import {
  verticalConfigurationsModel,
  VerticalConfigurationsModel,
} from "./verticalConfigurationsModel";
import { featuresModel, FeaturesModel } from "./featuresModel";
import {
  marketingAttributionModel,
  MarketingAttributionModel,
} from "./marketingAttributionModel";
import { contactConfigModel, ContactConfigModel } from "./contactConfig";
import { packageDraftModel, PackageDraftModel } from "./packages";
import { packageStore, PackageStore } from "./jobs/packageStore";

export interface StoreModel {
  currentUser: CurrentUserModel;
  jobs: JobsModel;
  preferences: PreferencesModel;
  contentful: ContentfulModel;
  s3: S3Model;
  draftTalent: DraftTalentModel;
  agencyDashboardFilters: AgencyDashboardFiltersModel;
  verticalConfigurations: VerticalConfigurationsModel;
  features: FeaturesModel;
  marketingAttribution: MarketingAttributionModel;
  contactConfig: ContactConfigModel;
  packageDraftModel: PackageDraftModel;
  package: PackageStore;
}

export const storeModel: StoreModel = {
  currentUser: currentUserModel,
  jobs: jobsModel,
  preferences: preferencesModel,
  contentful: contentfulModel,
  s3: s3Model,
  draftTalent: draftTalentModel,
  agencyDashboardFilters: agencyDashboardFiltersModel,
  verticalConfigurations: verticalConfigurationsModel,
  features: featuresModel,
  marketingAttribution: marketingAttributionModel,
  contactConfig: contactConfigModel,
  packageDraftModel: packageDraftModel,
  package: packageStore,
};

const storeName = "EasyPeasyStore";
const storeVersion = 0;
export const storeLocalStorageKey = (key: string) =>
  `[${storeName}][${storeVersion}][${key}]`;

export const store = createStore(storeModel, {
  name: storeName,
  version: storeVersion,
});
