import { useState, useReducer, useCallback, useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { isEmpty, snakeCase } from "lodash";
import { TableList } from "./jobTableList";
import { KanbanBoard } from "./jobKanbanBoard";
import { Box, LegacyContainer, Flex } from "src/ccl/layout";
import { ApiError } from "src/components/errors";

import {
  Query,
  JobFilterInput,
  JobState,
  Assignee,
  User,
  TalentVertical,
  SortOrderInput,
  SortDirection,
  BookerProfile,
} from "src/graphql/types";
import { useStoreModel } from "src/hooks";
import { ASSIGNED_JOB_COUNTS_QUERY } from "src/graphql/queries";
import { Icon, Text } from "src/ccl/document";
import { Button, LinkButton } from "src/ccl/navigation";
import { JobFilter } from "src/components/dashboards/agents/jobs/JobFilter";
import { BOOKER_BRANDS_QUERY } from "src/graphql/queries/booker/BookerBrands";

const filterableStates = [
  JobState.PendingApproval,
  JobState.Approved,
  JobState.AwaitingPayment,
  JobState.Finalized,
  JobState.Completed,
  JobState.Cancelled,
  JobState.Rejected,
  JobState.Expired,
];

// We need to combine all of the sub-queries from the columns, then add them together for the filter job count.
// see the useEffect within the Kanbanboard Component below.
const totalCountReducer = (
  state: { [key: string]: number },
  action: { type: string; column: string; count: number },
) => {
  switch (action.type) {
    case "set":
      return { ...state, ...{ [action.column]: action.count } };
    default:
      throw new Error();
  }
};

const defaultFilters = (
  isOwnContext: boolean,
  currentUserId: string,
): JobFilterInput => {
  const baseFilters = {
    nameOrBrand: "",
    states: Object.keys(JobState)
      .map((state) => snakeCase(state).toUpperCase())
      .filter((c) => c !== "CANCELLED"),
  };

  if (isOwnContext) {
    return {
      ...baseFilters,
      assigneeId: currentUserId,
    };
  } else {
    return {
      ...baseFilters,
      assigneeId: "",
    };
  }
};

interface JobListProps {
  isOwnContext: boolean;
  currentUser: User | null;
}

export const JobList = ({ isOwnContext, currentUser }: JobListProps) => {
  const { agencyKanbanMode } = useStoreModel("preferences");
  const { isBooker } = useStoreModel("currentUser");

  const [view, setView] = useState<"simple" | "status">(
    agencyKanbanMode.preference || "status",
  );

  const defaultSortOrder: SortOrderInput = {
    column: "start_date",
    direction: SortDirection.Asc,
  };
  const [sortOrder, setSortOrder] = useState<SortOrderInput>(defaultSortOrder);
  const [createJobMobile, setCreateJobMobile] = useState(false);
  const toggleCreateJobButton = useCallback(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        !target.closest("[data-create-job-mobile]") &&
        !target.closest("[data-create-job-button]")
      ) {
        setCreateJobMobile(false);
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
    setCreateJobMobile((prev) => {
      if (!prev) {
        document.addEventListener("mousedown", handleClickOutside);
      }
      return !prev;
    });
  }, []);

  const [totalJobCount, setTotalJobCount] = useState<number>(0);
  const [kanbanColumnJobCount, dispatchKanbanTotalJobCount] = useReducer(
    totalCountReducer,
    {},
  );
  const kanbanTotalJobCount = useCallback(
    () => Object.values(kanbanColumnJobCount).reduce((v, i) => v + i, 0),
    [kanbanColumnJobCount],
  );

  const jobCount = view === "status" ? kanbanTotalJobCount() : totalJobCount;

  const [getBookerBrands, { data: bookerBrands }] =
    useLazyQuery<Query>(BOOKER_BRANDS_QUERY);

  const profile = bookerBrands?.me?.profile as BookerProfile;
  const brandOptions = profile?.brands || [];

  useEffect(() => {
    if (isBooker) {
      getBookerBrands();
    }
  }, [isBooker]);

  const filterModel = useStoreModel("agencyDashboardFilters");
  const filters = !isEmpty(filterModel.filters)
    ? filterModel.filters
    : defaultFilters(isOwnContext, currentUser?.id || "");

  const { error, data } = useQuery<Query>(ASSIGNED_JOB_COUNTS_QUERY, {});

  let assignees: Assignee[] = [];
  if (data?.me) {
    assignees = [
      {
        id: data.me.id,
        name: data.me.name,
        featuredAsset: data.me.featuredAsset,
        assignedLiveJobCount: data.me.assignedLiveJobCount,
      },
      ...(data.me.agencyUsers || []),
    ];
  }

  const findCurrentAssignee = assignees.find(
    (assignee) => filters.assigneeId === assignee.id,
  );

  const initialOwnerOptions = assignees
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((user) => ({
      label: user.name,
      value: user.id,
    }));

  const onReset = () => {
    filterModel.setFilters({
      ...filters,
      nameOrBrand: "",
      jobType: [],
      states: [],
      assigneeId: undefined,
      dateRange: undefined,
    });
  };

  return (
    <LegacyContainer variant={view === "status" ? "full" : "gutter"}>
      <Flex
        css={{
          flex: 1,
          flexDirection: "column",
          gap: "20px",
          py: "$6",
        }}
      >
        <Flex css={{ justifyContent: "space-between", alignItems: "center" }}>
          <Flex css={{ gap: "$4" }}>
            <Text variant="nh3">All jobs</Text>
            <Text as="span" variant="b1">
              {`${jobCount || "No"} ${jobCount === 1 ? "job" : "jobs"}`}
            </Text>
          </Flex>
          {isBooker && (
            <Box css={{ position: "relative" }}>
              <LinkButton
                data-test-id="CreateJobButton"
                to="/dashboard/client/talent/select"
                variant="primaryCta"
                css={{
                  borderRadius: "$pill",
                  padding: "14px 16px",
                  display: "none",
                  "@bp2": { display: "flex" },
                }}
              >
                Create a job
              </LinkButton>

              <Box css={{ position: "relative" }}>
                <Button
                  data-create-job-mobile
                  variant="primaryCta"
                  css={{
                    borderRadius: "$round",
                    padding: "12px 16px",
                    width: 42,
                    height: 42,
                    display: "flex",
                    minWidth: 0,
                    "@bp2": { display: "none" },
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={toggleCreateJobButton}
                >
                  <Icon variant="plus" size={24} color="white" />
                </Button>

                {createJobMobile && (
                  <LinkButton
                    data-create-job-button
                    to="/dashboard/client/talent/select"
                    css={{
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      textTransform: "none",
                      backgroundColor: "$white",
                      top: "100%",
                      right: 0,
                      marginTop: "$2",
                      whiteSpace: "nowrap",
                      borderRadius: "$3",
                      padding: "14px 32px",
                      fontFamily: "$sansNew",
                      boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
                      width: 270,
                      height: 84,
                      fontSize: "$16",
                    }}
                  >
                    <Flex css={{ gap: "$2" }}>
                      <Icon variant="briefcase" size={20} />
                      Create a job
                    </Flex>
                  </LinkButton>
                )}
              </Box>
            </Box>
          )}
        </Flex>
        <JobFilter
          view={view}
          onViewChange={(newView: "simple" | "status") => {
            setView(newView);
            agencyKanbanMode.setPreference(newView);
          }}
          jobCount={jobCount}
          onReset={onReset}
          nameOrBrand={filters.nameOrBrand || ""}
          setNameOrBrand={(newName) => {
            filterModel.setFilters({
              ...filters,
              ...{ nameOrBrand: newName },
            });
          }}
          verticals={(filters.jobType as TalentVertical[]) || []}
          setVerticals={(newJobTypes) => {
            filterModel.setFilters({ ...filters, jobType: newJobTypes });
          }}
          availableJobStates={filterableStates}
          states={(filters.states as JobState[]) || []}
          setStates={(newStates) => {
            filterModel.setFilters({
              ...filters,
              ...{ states: newStates },
            });
          }}
          owner={
            findCurrentAssignee
              ? {
                  value: findCurrentAssignee?.id,
                  label: findCurrentAssignee?.name,
                }
              : undefined
          }
          setOwner={(newId) => {
            filterModel.setFilters({ ...filters, ...{ assigneeId: newId } });
          }}
          ownerOptions={initialOwnerOptions}
          dateRange={filters.dateRange}
          onDateRangeChange={(newDateRange) =>
            filterModel.setFilters({
              ...filters,
              ...{ dateRange: newDateRange },
            })
          }
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          brands={filters.brands}
          setBrands={(brands) => {
            filterModel.setFilters({ ...filters, brands });
          }}
          brandOptions={brandOptions}
        />
        <Box
          css={{
            flex: 1,
            overflowX: view === "status" ? "auto" : "initial",
            "::-webkit-scrollbar": {
              width: "7px",
            },
          }}
        >
          {error ? (
            <ApiError />
          ) : (
            <>
              {view === "status" ? (
                <KanbanBoard
                  isOwnContext={isOwnContext}
                  assignees={assignees}
                  filters={filters}
                  setColumnJobCount={(count: number, column: string) => {
                    dispatchKanbanTotalJobCount({
                      type: "set",
                      column: column,
                      count: count,
                    });
                  }}
                />
              ) : (
                <TableList
                  isOwnContext={isOwnContext}
                  assignees={assignees}
                  filters={filters}
                  setTotalJobCount={(count: number) => setTotalJobCount(count)}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                />
              )}
            </>
          )}
        </Box>
      </Flex>
    </LegacyContainer>
  );
};
